import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../src/assets/css/reset.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import i18n from './local/index'
import NavigationBar from '@/components/NavigationBar/index.vue'
import Footer from '@/components/Footer/index.vue'
// 导入字体
import './assets/fonts/font.css'

import 'quill/dist/quill.core.css'

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'kk5uByWnYy3UHwVCDZUQeHXf35ZZV3Yx' // 这个地方是官方提供的ak密钥
})

Vue.component('NavigationBar', NavigationBar)
Vue.component('FooTer', Footer)

Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
