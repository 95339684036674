export default {
  namespaced: true,
  state: {
    language: 0,
    languageVal: 'zh',
		
		// menus: [],
		menus: (localStorage.getItem('menus') && JSON.parse(localStorage.getItem('menus'))) || [],
		
		breadcrumbs: (localStorage.getItem('breadcrumbs') && JSON.parse(localStorage.getItem('breadcrumbs'))) || {},
		
    cppath: '/productCenter/bialene',
    fwpath: '/serviceCenter/kit',
    xwpath: '/newsCenter/news'
  },
  mutations: {
    CHANGE_NAV_STATE (state) {
      if (state.language === 1) {
        state.language = 0
        state.languageVal = 'zh'
      } else {
        state.language = 1
        state.languageVal = 'en'
      }
    },
		CHANGE_MENUS (state, menu) {
			state.menus = menu
			localStorage.setItem('menus', JSON.stringify(menu))
		},
		CHANGE_BREADCRUMBS (state, newValue) {
			state.breadcrumbs = newValue
			localStorage.setItem('breadcrumbs', JSON.stringify(newValue))
		},
    CHANGE_PATH_STATE (state, newPath) {
      state.cppath = newPath
    },
    CHANGE_PATH_FW (state, newPath) {
      state.fwpath = newPath
    },
    CHANGE_PATH_XW (state, newPath) {
      state.xwpath = newPath
    }
  }
}
