export default {
  button: {
    add: '按钮',
    cklj: '查看了解',
    ckgd: '查看更多',
    ckxq: '查看详情',
    ckqb: '查看全部',
    lxwm: '联系我们',
    fhml: '返回目录',
    fhsy: '返回上页',
    jrwm: '加入我们',
    xjzx: '询价中心'
  },
	ljwm: {
		tit: '了解凯泰莱铂',
		secTitle: '安徽凯泰莱铂科技有限公司',
		intro: '安徽凯泰莱铂科技有限公司成立于2022年08月08日，由国家杰出青年基金获得者复旦大学张俊良教授创立，是合肥先导功能分子研究院孵化的重点企业之一。公司主要从事手性催化剂及配体的研发和生产，医药中间体创新工艺开发和生产，提供高通量催化剂筛选和定制合成服务。',    intro2: '凯泰莱铂秉承做“好”工艺、用“好”催化剂的企业理念，专注于催化剂和医药中间体定制，创制和工艺开发，拥有品类齐全的手性催化剂库，包括特色手性膦配体SadPhos系列（Ming-Phos系列，Xu-Phos系列）、二茂铁系列、手性双胺、手性卡宾配体、手性亚磷酰胺、手性噁唑啉和手性磷酸系列等，服务国内外医药、农药和精细化学品制造企业和学术机构。',
	},
	yzcg: {
		tit: '特色产品展示'
	},
	yfycx: {
		tit: '研发与创新',
		leftTit: '专注手性配体研发创新',
		cl: '成立公司',
		yf: '专利配体',
		ry: '量产配体',
		zl: '已授权专利',
		desc1: '凯泰莱铂于2022年8月在合肥高新区注册成立，公司主要从事高端手性膦配体的产业化开发。创始人来自研究院的院士团队成员、复旦大学张俊良教授。张俊良教授是科技部国家重点研发计划项目“高端金属与配体研制及关键技术研发”首席科学家、杰青获得者、科技部中青年创新领军人才，先后承担国家多个重点项目。',
		desc2: '公司以自主高端手性试剂研发、高端定制合成和医化企业产品新工艺开发为商业发展逻辑，建立一个围绕着合成高端手性膦配体的底层平台技术作为支撑的产业发展体系。为国内外从事手性药物、手性农药、手性功能材料开发和生产的企事业等单位提供手性配体或催化剂；为国内外从事手性药物，手性农药开发和生产的企事业等单位提供手性技术方面的开发，技术咨询，技术转让和技术服务；为国内高附加值的精细化工品研发提供新的生产技术，解决卡脖子产品技术研发和生产，打破国外产品的垄断。',
	},
	aboutus: {
		company: '安徽凯泰莱铂科技有限公司',
		tit: '公司地址',
	  address: '地址',
	  phone: '电话',
	  email: '邮箱',
	  fixPhone: '固话',
		people: '联系人',
		zjl: '张俊良',
		
		addressContent: '合肥市蜀山区国家健康大数据产业园C2-01栋三楼',
		phoneContent: '17764458457',
		emailContent: '3929675055@qq.com',
		fixPhoneContent: '17764458457',
		
		gsjs: {
			slogan: '做“好”工艺；用“好”催化剂',
			intro: '公司以自主高端手性试剂研发、高端定制合成和医化企业产品新工艺开发为商业发展逻辑，建立一个围绕着合成高端手性膦配体的底层平台技术作为支撑的产业发展体系。为国内外从事手性药物、手性农药、手性功能材料开发和生产的企事业等单位提供手性配体或催化剂；为国内外从事手性药物，手性农药开发和生产的企事业等单位提供手性技术方面的开发，技术咨询，技术转让和技术服务；为国内高附加值的精细化工品研发提供新的生产技术，解决卡脖子产品技术研发和生产，打破国外产品的垄断。',
		},
		qywh: {
			mb: '公司目标',
			mbCont: '专注于为市场提供最优良的产品；致力于为客户提供最优质的服务。',
			js: '团队精神',
			jsCont: '明确目标、发挥优势、汲取教训、实现梦想。',
			ln: '经营理念',
			lnCont: '以筑梦立业为宗旨，为自我和公司创造卓越成就。'
		},
		gytd: {
			tit: '团队背景',
			desc: '安徽凯泰莱铂科技有限公司成立于2022年08月08日，由国家杰出青年基金获得者，复旦大学张俊良教授创立。公司主要从事手性催化剂、不对称氢化的设计与开发，手性药物，中间体的合成，手性技术相关的技术开发，技术转让，技术咨询和技术服务。 公司坐落于徽省合肥市高新区国家健康大数据产业园，附近厂区林立，周围入驻大中型企业，合作机会丰富。公司在技术上与国内知名企业合作，配备溶剂纯化装置等常见仪器，通过网络平台支持，不断自我提升和优化，产品广受客户的支持。',
			
			kytd: '科研团队',
			kytdry: {
				zjl: {
					name: '张俊良',
					position: '公司创始人',
					introduce: '复旦大学教授、博导<br />国家杰出青年基金获得者<br />科技部国家重点研发计划首席科学家',
				},
				zzm: {
					name: '张展鸣',
					position: '技术总监',
					introduce: '复旦大学青年研究员<br />华东师范大学博士',
				},
				yjf: {
					name: '杨俊峰',
					position: '工艺总监',
					introduce: '复旦大学青年研究员<br />新加坡南洋理工大学博士',
				},
				zk: {
					name: '张坤',
					position: '研发部组长',
					introduce: '',
				},
				zxn: {
					name: '张晓南',
					position: '手性配体研发组长',
					introduce: '',
				},
				yyy: {
					name: '杨友洋',
					position: '不对称氢化研发组长',
					introduce: '',
				},
				sjw: {
					name: '沈靖武',
					position: '定制合成研发组长',
					introduce: '',
				},
				yjj: {
					name: '杨俊杰',
					position: '研发部研究员',
					introduce: '',
				},
			}
		},
		yfscjd: {
			tit: '研发和生产基地',
		},
		ryzz: {
			zscq: '通过多项专利及知识产权',
			desc: '安徽凯泰莱铂科技有限公司成立于2022年08月08日，由国家杰出青年基金获得者，复旦大学张俊良教授创立。公司主要从事手性催化剂、不对称氢化的设计与开发，手性药物，中间体的合成，手性技术相关的技术开发，技术转让，技术咨询和技术服务。',
			zs: {
				zs1: '一类环状膦骨架的手性单膦催化剂Le-Phos以及其全构型的制备方法和应用',
				zs2: '一类手性双齿氮磷配体Rong-Phos及其制备方法和应用',
				zs3: '一类二茂铁骨架的手性单膦配体WJ-Phos及制备方法和应用',
			}
		}
	},
	news: {
		tit: '新闻资讯',
	},
	hzhb: {
		tit: '优质的合作伙伴',
	},
  sxfw: {
    tit: '筛选服务',
		le: {
			title: '一类环状膦骨架的手性单膦催化剂Le-Phos以及其全构型的制备方法和应用',
			desc: '本发明的目的是提供一类手性单膦催化剂Le-Phos及其全构型的制备方法和应用，可高效、简单及低成本的制备全部立体构型的所述手性单膦催化剂Le-Phos。'
		},
		rong: {
			title: '一类手性双齿氮磷配体Rong-Phos及其制备方法和应用',
			desc: '本发明的目的是提供一类新型手性双齿氮膦配体Rong-Phos及其制备方法和Rong-Phos铱络合物氮手性中心高对映选择性制备方法及其在不对称催化氢化中的应用。'
		},
		wj: {
			title: '一类二茂铁骨架的手性单膦配体WJ-Phos及制备方法和应用',
			desc: '本发明的目的是提供一类手性单膦配体WJ-Phos及制备方法和应用。该配体在铜催化联烯的硼酰化不对称反应中的应用，具有很高的反应活性和立体选择性，具有广泛的应用价值。'
		},
  },
  dzfw: {
    tit: '定制服务',
    contone: '手性中间体合成',
    conttow: '创新医药研发',
    contthree: '特色定制合成'
  },
  yhjs: {
    tit: '核心技术',
    contone: '技术研发领域：新型工业催化技术、药物发现与 药物递送技术、碳中和与清洁技术发展等领域',
    conttow: '技术开发团队：来自复旦大学、北京大学、中国科 学技术大学、浙江大学、华东师范大学等优秀团队',
    contthree: '特色技术：联烯化学、清洁氧化'
  },
  honor: {
    clgs: '成立公司',
    sjcg: '多个试剂开发成功',
    hdry: '获得荣誉',
    fmzl: '发明专利',
    hxjs: '核心技术'
  },
  development: {
    tit: '发展历程',
    contentone: '安徽凯泰莱铂科技有限公司创建于2022年，是由合肥溪轩科技有限公司与浙江新和成股份有限公司共同发起设立的创新研发型公司。',
    contenttow: '公司依托高校或研究所原创研发团队与新和成产业平台，发展功能分子产业链关键核心技术，为精细化工、生物医药、新能源、新材料等行业提供先进技术与产品。'
  },
	productCenter: {
		inputPlaceHolder: '请输入产品编码，CAS编码，关键字或结构式查询，',
		bz: '包装',
		jg: '价格',
		wxxj: '微信询价',
		lxfs: '联系方式',
		wechat: '微信号',
		contact: '联系人',
		tel: '电话',
		email: '邮箱',
		qq: 'QQ',
	},
  productdetail: {
    productName: '产品名称',
    zhName: '中文名称',
    casNo: 'CAS号',
		chandi: '产地',
		purity: '纯度',
		brand: '品牌',
		condition: '储存条件',
		category: '产品类别',
		use: '用途',
    chemicalFormula: '分子式',
    molecularWeight: '分子量',
    mdlNo: 'MDL NO',
    boilingPoint: '沸点',
    pubchemId: 'Pubchem ID',
    inChIKey: 'InChIKey',
    inItemNo: '产品编号',
    titone: '产品详情',
    titone2: '产品简介',
    tittow: '产品推荐',
    titthree: '医药中间体其他推荐',
    titFour: '丙炔醇产品其他推荐',
    titFive: '硼酸产品其他推荐',
    titSix: '配体产品其他推荐',
  },
	newsCenter: {
		view: '浏览量',
	},
	lyb: {
		xm: '姓名',
		dh: '电话',
		yx: '邮箱',
		gsmc: '公司名称',
		szdq: '所在地区',
		cpmc: '产品名称',
		xql: '需求量',
		qtxq: '其他需求',
		
		xmTs: '您的称呼',
		dhTs: '您的电话',
		yxTs: '您的邮箱',
		gsmcTs: '您的公司名称',
		szdqTs: '您的所在地区',
		cpmcTs: '您的产品名称',
		xqlTs: '您的需求量',
		qtxqTs: '您可以在此提出建议或留言，以便我们更好的了解您的需求',
		
		submit: '提交留言',
		btx: '请输入必填项',
		ok: '操作成功',
	},
  joinus: {
    zpgs: '招聘公司',
    zpzw: '招聘职位',
    gzdd: '工作地点',
    fbrq: '发布日期'
  }
}
