export default {
  button: {
    add: 'button',
    cklj: 'EVEN MORE',
    ckgd: 'view more',
    ckxq: 'view details',
    ckqb: 'view all',
    lxwm: 'contact us',
    fhml: 'return count',
		fhsy: 'return back',
    jrwm: 'join us',
    xjzx: 'inquiry price'
  },
	ljwm: {
		tit: 'About us',
		secTitle: 'Anhui Cat-Lab technology Co., LTD',
		intro: 'Anhui Cat-Lab Technology Co., Ltd. was founded on August 8, 2022, by Professor Zhang Junliang of Fudan University, winner of the National Outstanding Youth Fund, and is one of the key enterprises incubated by Hefei Institute of Leading Functional Molecules. The company is mainly engaged in the research and development and production of chiral catalysts and ligands, the development and production of pharmaceutical intermediates innovative processes, and provides high-throughput catalyst screening and custom synthesis services.',
		intro2: 'Adhering to the corporate philosophy of "good" catalyst with "good" process, Cat-Lab focuses on catalyst and pharmaceutical intermediates customization, creation and process development, and has a complete range of chiral catalysts library, including the characteristic chiral phosphine ligand SadPhos series (Ming Phos series, Ming PHOS series, Ming PHOS series, Ming PHOS series, Xu-Phos series), ferrocene series, chiral diamines, chiral carbene ligands, chiral phosphoramides, chiral oxazoline and chiral phosphoric acid series, serving domestic and foreign pharmaceutical, pesticide and fine chemical manufacturing enterprises and academic institutions.',
	},
	yzcg: {
		tit: 'Featured product display'
	},
	yfycx: {
		tit: 'R&d and innovation',
		leftTit: 'Focus on chiral ligand development innovation',
		cl: 'incorporation',
		yf: 'Patented ligand',
		ry: 'Mass production of ligands',
		zl: 'Granted patent',
		desc1: 'Cat-Lab was registered and established in Hefei High-tech Zone in August 2022. The company is mainly engaged in the industrialization development of high-end chiral phosphine ligands. The founder is Professor Zhang Junliang from Fudan University, a member of the academician team of the Institute. Professor Zhang Junliang is the chief scientist of the National Key research and Development program of the Ministry of Science and Technology "High-end metal and ligand development and key technology research and development", the winner of Jieqing, and the young and middle-aged innovation leading talents of the Ministry of Science and Technology, and has undertaken a number of key national projects.',
		desc2: 'The company takes independent research and development of high-end chiral reagents, high-end custom synthesis and new process development of medical and chemical enterprise products as its business development logic, and establishes an industrial development system supported by the underlying platform technology for synthesizing high-end chiral phosphine ligands. Provide chiral ligands or catalysts for domestic and foreign enterprises engaged in the development and production of chiral drugs, chiral pesticides and chiral functional materials; Provide chiral technology development, technical consultation, technology transfer and technical services for domestic and foreign enterprises engaged in the development and production of chiral drugs and chiral pesticides; Provide new production technology for domestic research and development of high value-added fine chemicals, solve the problem of product technology research and development and production, and break the monopoly of foreign products.',
	},
	aboutus: {
		company: 'Anhui Cat-Lab technology Co., LTD',
		tit: 'Company address',
	  address: 'address',
	  phone: 'phone',
	  email: 'e-mail',
	  fixPhone: 'fixed-line telephone',
		people: 'Contact person',
		zjl: 'Zhang Junliang',
		
		addressContent: 'Third floor, Building C2-01, National Health Big Data Industrial Park, Shushan District, Hefei City',
		phoneContent: '17764458457',
		emailContent: '3929675055@qq.com',
		fixPhoneContent: '17764458457',
		
		gsjs: {
			slogan: 'Do "good" craft; Use a "good" catalyst',
			intro: "The company takes independent research and development of high-end chiral reagents, high-end custom synthesis and new process development of medical and chemical enterprise products as its business development logic, and establishes an industrial development system supported by the underlying platform technology for synthesizing high-end chiral phosphine ligands. Provide chiral ligands or catalysts for domestic and foreign enterprises engaged in the development and production of chiral drugs, chiral pesticides and chiral functional materials; Provide chiral technology development, technical consultation, technology transfer and technical services for domestic and foreign enterprises engaged in the development and production of chiral drugs and chiral pesticides; Provide new production technology for domestic research and development of high value-added fine chemicals, solve the problem of product technology research and development and production, and break the monopoly of foreign products.",
		},
		qywh: {
			mb: 'Corporate objectives',
			mbCont: 'Focus on providing the best products for the market; Committed to providing customers with the best quality service.',
			js: 'Team spirit',
			jsCont: 'Clear goals, leverage strengths, learn lessons, and realize dreams.',
			ln: 'Business philosophy',
			lnCont: 'With the purpose of building dreams and career, we create outstanding achievements for ourselves and the company.'
		},
		gytd: {
			tit: 'Team background',
			desc: 'Anhui Kaitaibo Technology Co., Ltd. was established on August 8, 2022, by the winner of the National Outstanding Youth Fund, Fudan University Professor Zhang Junliang founded. The company is mainly engaged in the design and development of chiral catalysts, asymmetric hydrogenation, synthesis of chiral drugs, intermediates, chiral technology-related technology development, technology transfer, technical consulting and technical services. The company is located in the National Health Big Data Industrial Park, High-tech Zone, Hefei, Anhui Province. There are many factories nearby, large and medium-sized enterprises settled around, and abundant cooperation opportunities. The company in the technical cooperation with well-known domestic enterprises, equipped with solvent purification equipment and other common instruments, through the network platform support, continuous self-improvement and optimization, products are widely supported by customers.',
			
			kytd: 'Scientific research team',
			kytdry: {
				zjl: {
					name: 'Zhang Junliang',
					position: 'Company founder',
					introduce: 'Fudan University professor, doctoral supervisor, <br />winner of the National Outstanding Youth Fund, <br />Chief scientist of the National Key Research and Development Program of the Ministry of Science and Technology',
				},
				zzm: {
					name: 'Zhang Zhanming',
					position: 'Technical director',
					introduce: 'Young researcher of Fudan University, Doctor of East China Normal University',
				},
				yjf: {
					name: 'Yang Junfeng',
					position: 'Process director',
					introduce: 'Young Researcher, Fudan University, Doctor, Nanyang Technological University, Singapore',
				},
				zk: {
					name: 'Zhang Kun',
					position: 'Research and development team leader',
					introduce: '',
				},
				zxn: {
					name: 'Zhang Xiaonan',
					position: 'Head of chiral ligand development',
					introduce: '',
				},
				yyy: {
					name: 'Yang Youyang',
					position: 'Asymmetric hydrogenation R&D team leader',
					introduce: '',
				},
				sjw: {
					name: 'Shen Jingwu',
					position: 'Custom synthesis R&D team leader',
					introduce: '',
				},
				yjj: {
					name: 'Yang Junjie',
					position: 'Research and Development researcher',
					introduce: '',
				},
			}
		},
		yfscjd: {
			tit: 'Research and development and production bases',
		},
		ryzz: {
			zscq: 'Through a number of patents and intellectual property',
			desc: 'Anhui Cat-Lab Technology Co., Ltd. was established on August 8, 2022, by the winner of the National Outstanding Youth Fund, Fudan University Professor Zhang Junliang founded. The company is mainly engaged in the design and development of chiral catalysts, asymmetric hydrogenation, synthesis of chiral drugs, intermediates, chiral technology-related technology development, technology transfer, technical consulting and technical services.',
			zs: {
				zs1: 'Preparation method and application of a class of chiral monophosphine catalysts with cyclic phosphine skeleton, Le-Phos and its complete configuration',
				zs2: 'A class of chiral bidentate nitrogen and phosphorus ligand Rong-Phos, preparation method and application thereof',
				zs3: 'Chiral monophosphine ligand WJ-Phos of ferrocene skeleton and its preparation method and application',
			}
		}
	},
	news: {
		tit: 'News information',
	},
	hzhb: {
		tit: 'Quality partner',
	},
  sxfw: {
    tit: 'Screening service',
    le: {
    	title: 'Preparation method and application of a class of chiral monophosphine catalysts with cyclic phosphine skeleton, Le-Phos and its complete configuration',
    	desc: 'The invention aims to provide a kind of chiral monophosphine catalyst Le-Phos and its full configuration preparation method and application, which can efficiently, simply and low-cost prepare the chiral monophosphine catalyst Le-Phos with full three-dimensional configuration.'
    },
    rong: {
    	title: 'A class of chiral bidentate nitrogen and phosphorus ligand Rong-Phos, preparation method and application thereof',
    	desc: 'The invention aims to provide a novel chiral bisentate phosphine nitrogen ligand Rong-Phos and a preparation method thereof, and a high enantioselectivity preparation method of Rong-Phos iridium complex nitrogen chiral center and its application in asymmetric catalytic hydrogenation.'
    },
    wj: {
    	title: 'Chiral monophosphine ligand WJ-Phos of ferrocene skeleton and its preparation method and application',
    	desc: 'The invention aims to provide a class of chiral monophosphine ligand WJ-Phos and a preparation method and application thereof. The application of this ligand in the boroacylation asymmetric reaction of bialene catalyzed by copper has high reactivity and stereoselectivity, and has wide application value.'
    },
  },
  dzfw: {
    tit: 'Custom synthesis',
    contone: 'Chiral intermediate synthesis',
    conttow: 'Innovative pharmaceutical research and development',
    contthree: 'Characteristic custom synthesis'
  },
  yhjs: {
    tit: 'Core Technology',
    contone: 'Technology research and development: new industrial catalytic technology, drug discovery and drug delivery technology, carbon neutrality and clean technology development',
    conttow: 'Technology development team: Excellent team from Fudan University, Peking University, University of Science and Technology of China, Zhejiang University, East China Normal University, etc',
    contthree: 'Characteristic technology: bialene chemistry, clean oxidation'
  },
  honor: {
    clgs: 'founding a company',
    sjcg: 'several reagents were successfully developed',
    hdry: 'achieve distinction',
    fmzl: 'patent for an invention',
    hxjs: 'core technology'
  },
  development: {
    tit: 'Development History',
    contentone: 'Anhui Cat-Lab Technology Co., LTD., founded in 2022, is an innovative research and development company jointly initiated by Hefei Xixuan Technology Co., Ltd. and Zhejiang Xinhecheng Co., LTD.',
    contenttow: 'Relying on the original research and development team of universities or institutes and the Xinhecheng industry platform, the company develops the key core technologies of the functional molecule industry chain, and provides advanced technologies and products for the industries of fine chemicals, biomedicine, new energy and new materials.'
  },
	productCenter: {
		inputPlaceHolder: 'Please enter product code, CAS code, keyword or structured query',
		bz: 'Package',
		jg: 'Price',
		wxxj: 'Wechat inquiry',
		lxfs: 'Contact information',
		wechat: 'Wechat',
		contact: 'Contact person',
		tel: 'Telephone',
		email: 'Mail',
		qq: 'QQ',
	},
  productdetail: {
    productName: 'Product Name',
    zhName: 'Chinese Name',
    casNo: 'CAS NO',
		chandi: 'Place Of Origin',
		purity: 'Purity',
		brand: 'Brand',
		condition: 'Storage condition',
		category: 'Product category',
		use: 'use',
    chemicalFormula: 'Molecular Formula',
    molecularWeight: 'Molecular Weight',
    mdlNo: 'MDL NO',
    boilingPoint: 'Boiling Point',
    pubchemId: 'Pubchem ID',
    inChIKey: 'InChIKey',
    inItemNo: 'Product number',
    titone: 'Product Information',
		titone2: 'Product introduction',
    tittow: 'Products recommendations',
    titthree: 'Pharmaceutical intermediates other recommendations',
		titFour: 'Propargyl alcohol products Other recommended',
		titFive: 'Boric acid products Other recommended',
		titSix: 'Ligand products Other recommendations',
  },
	newsCenter: {
		view: 'view',
	},
	lyb: {
		xm: 'Name',
		dh: 'telephone',
		yx: 'mailbox',
		gsmc: 'Company name',
		szdq: 'Local area',
		cpmc: 'Product name',
		xql: 'Quantity demanded',
		qtxq: 'Other needs',
		
		xmTs: 'Your name',
		dhTs: 'Your call',
		yxTs: 'Your email address',
		gsmcTs: 'Your company name',
		szdqTs: 'Your area',
		cpmcTs: 'Your product name',
		xqlTs: 'Your demand',
		qtxqTs: 'You can make suggestions or comments here, so that we can better understand your needs',
		
		submit: 'Submit a message',
		btx: 'Please enter the required fields',
		ok: 'Successful operation',
	},
  joinus: {
    zpgs: 'Recruitment agency',
    zpzw: 'Position vacant',
    gzdd: 'Workplace',
    fbrq: 'Release date'
  }
}
